import { useEffect, useState } from 'react';
import './App.css';
import Loader from './components/Loader';
import ScrollToTop from './components/ScrollToTop';
import "./style.css";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import Home from './components/Home/Home';
import Navbar from './components/Navbar';
import "bootstrap/dist/css/bootstrap.min.css";


function App() {
  const [load, setLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoad(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <Router>
      <Loader load={load} />
      <div className="App" id={load ? "no-scroll" : "scroll"}>
      <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
        {/* <Footer /> */}
      </div>
    </Router>
  );
}

export default App;
